import React, { useEffect, useState } from 'react'
import tw, {css} from "twin.macro"
import useScript from '../hooks/useScript'

const windowGlobal = typeof window !== 'undefined' && window

export const AdPlayerProVideo = ({options, vastXml, onReady, onDone, ...props}) => {

	const scriptStatus = useScript("https://serving.stat-rock.com/player/fileio.js")
	// const scriptStatus = 'ready'

	const videoRef = React.useRef(null);
	const playerRef = React.useRef(null);
	const [done, setDone] = useState(false)

	const videoDone = () => {
		setDone(true)
	}

	useEffect(() => {
		
		if (done) {
			if (onDone) {
				onDone()
			}
		}

	}, [done])

	useEffect(() => {

		if (!windowGlobal || scriptStatus !== 'ready' || playerRef.current) {
			return
		}

		const adp = playerRef.current = AdPlayerPro('VCGGlMM9VXal').setup({
			"placementId": "VCGGlMM9VXal",
			"type": "inBanner",
			"autoStart": true,
			"muted": true,
			"aspectratio": "16:9",
			"advertising": {
				"barColor":"#886FF4",
				"tag": [
					{
						"url": vastXml,
						"type": "xml",
						"client": "vast"
					}
				]
			},
		})

		adp.on("AdStopped", () => { videoDone() })
		adp.on("AdSkipped", () => { videoDone() })
		adp.on("AdError", () => { videoDone() })
		adp.on("AdUserClose", () => { videoDone() })

	}, [scriptStatus]);

	useEffect(() => {

		const player = playerRef.current;

		return () => {
			if (player) {
				player.off("AdStopped")
				player.off("AdSkipped")
				player.off("AdError")
				player.off("AdUserClose")
				player.remove()
				playerRef.current = null
			}
		}

	}, [playerRef]);

	return (
		<>
		<div id="VCGGlMM9VXal" tw="w-full" css={[
			done && {...tw`hidden`}
		]}></div>
		</>
	)
}

export default AdPlayerProVideo;